<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Quel est le produit de la réaction suivante?</p>

      <p class="pl-12 mb-4">
        <v-img style="max-width: 183px" :src="imageHeadName" />
      </p>

      <v-radio-group v-model="inputs.input1" class="mb-0" :disabled="!allowEditing">
        <v-radio class="my-2" value="a">
          <template #label>
            <v-img :src="imageAName" style="max-width: 183px" />
          </template>
        </v-radio>

        <v-radio class="my-2" value="b">
          <template #label>
            <v-img :src="imageBName" style="max-width: 183px" />
          </template>
        </v-radio>

        <v-radio class="my-2" value="c">
          <template #label>
            <v-img :src="imageCName" style="max-width: 183px" />
          </template>
        </v-radio>

        <v-radio class="my-2" value="d">
          <template #label>
            <v-img :src="imageDName" style="max-width: 183px" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'chemUott1721B_Task30',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    imageHeadName() {
      return '/img/assignments/task30_main.png';
    },
    imageAName() {
      return '/img/assignments/task30_optionA.png';
    },
    imageBName() {
      return '/img/assignments/task30_optionB.png';
    },
    imageCName() {
      return '/img/assignments/task30_optionC.png';
    },
    imageDName() {
      return '/img/assignments/task30_optionD.png';
    },
  },
};
</script>
